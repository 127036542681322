html,
body {
  overflow: hidden;
}

#MainPage {
  height: 100%;
  overflow: hidden;

  .rs-loader-wrapper {
    max-width: 100%;
    height: 100%;
    z-index: 99999;
  }

  .rs-loader-backdrop {
    background: #333;
    opacity: 0.5;
  }

  .rs-loader-spin {
    display: none;
  }

  .container {
    position: relative;
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    #header-row {
      position: fixed;
      width: 100%;
      height: 52px;
      background: #2c3238;
      margin: 0;
      z-index: 999;
    }

    #body-row {
      padding: 0 10%;
      display: block;
      position: absolute;
      top: 52px;
      left: 0;
      width: 100%;
      height: calc(100% - 52px);
      background: #ffffff;
      overflow-y: auto;
      margin: 0;
      z-index: 7;

      >.css-79elbk {
        min-height: 100%;
      }
    }

    #sideNav {
      background: #ffffff;
    }

    .sideNav-expanded {
      width: 230px;
    }

    .sideNav-none {
      width: 20px;

      .flex-column.nav {
        display: none;
      }

      .card-box.rs-panel {
        display: none;
      }
    }

    #toggleButton {
      z-index: 1;
    }

    .rs-btn-icon-circle {
      -webkit-box-shadow: rgba(9, 30, 66, 0.08) 0 0 0 1px, rgba(9, 30, 66, 0.08) 0 2px 4px 1px;
      box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08), 0 2px 4px 1px rgba(9, 30, 66, 0.08);
      top: 10px;
      left: -15px;
    }

    .rs-btn-icon-circle:focus {
      background: #575757;
      color: #fff;
    }

    .body-content {
      overflow-x: auto;
      background-color: #fff;

      .menu-title {
        margin-bottom: 20px;

        >span {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
        }

        .menu-info {
          color: #6B7682;
          font-size: 12px;
          margin-left: 15px;
        }
      }

      .form {
        i.required {
          background: url(../../icons/oval.svg);
          width: 4px;
          height: 4px;
          position: absolute;
          margin-left: 4px;
          margin-top: 2px;
        }
      }

      .btn {
        box-shadow: none;
        width: max-content;
      }
    }
  }

  .MuiTableCell-paddingNone {
    padding: 0 !important;
  }

  .MuiIconButton-root:hover {
    background: none;
  }

  .MaterialTableWrapper {
    width: 100%;
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiTable-root {
    .MuiTableRow-head {
      border-top: 2px solid #2C3238;
      border-bottom: 1px solid #A5B3C2;
    }

    .MuiTableRow-root {
      height: 36px;
      font-size: 12px;
    }

    .MuiTableCell-head {
      height: 38px;
      padding: 4px 12px;
      border-bottom: none;

      div {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.1px;
        line-height: 18px;
        color: #2c3238;
      }
    }

    .MuiCheckbox-root {
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
          color: #a5b3c2;
        }
      }

      &.Mui-checked {
        .MuiIconButton-label {
          .MuiSvgIcon-root {
            color: #26b9d1;
          }
        }
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }

    .MuiTableRow-hover:hover {
      background: #F0FDFF;
    }

    .MuiTableCell-head {
      &:first-child {
        width: 9px;
      }
    }

    .MuiTableCell-body {
      &:first-child {
        width: 0;

        div {
          .MuiIconButton-root {
            padding: 0;
          }
        }
      }
    }

    .MuiTableCell-body {
      height: 36px;
      border-bottom: 1px solid #e9eff5;
      font-family: 'Noto Sans KR';
      letter-spacing: 0.07px;
      font-size: 12px;
      letter-spacing: 0.1px;
      color: #424952;
      padding: 3px;

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.1px;
      }

      #moreButton {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 20px;
        background: transparent;
        border: none;
        color: #6b7682;
        padding: 0;
        background: url('../../icons/common/ic-more.svg') no-repeat center;

        i {
          display: none;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}