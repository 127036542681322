#BoardListSection {
  width: 100%;

  .MuiTable-root .MuiTableCell-head {
    font-family: 'Noto Sans KR';
    font-size: 12px;
  }
}

#BoardListSectionFooter {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;

  .rs-table-pagination-toolbar {
    flex: 0 0 auto;
    padding: 0;

    .rs-table-pagination-length-menu {
      .rs-picker-select {
        .rs-btn {
          width: 120px;
          height: 32px;
          padding: 7px 36px 7px 12px;
          border: 1px solid #bec9d4;
          border-radius: 3px;

          .rs-picker-toggle-value {
            color: #8996a3;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.05px;
            line-height: 16px;
          }

          .rs-picker-toggle-caret {
            top: 11px;
            right: 13px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-left: 3.5px solid transparent;
              border-right: 3.5px solid transparent;
              border-top: 4px solid #000;
            }
          }
        }
      }
    }

    .rs-divider {
      display: none;
    }

    .rs-table-pagination-end {
      margin-left: 30px;
    }

    .rs-pagination {
      .rs-pagination-btn {
        float: left;
        margin: 0 1px;

        a {
          width: 32px;
          height: 32px;
          padding: 0;
          border-radius: 50%;
          color: #2c3238;
          font-weight: 400;
          font-size: 11px;
          letter-spacing: 0.15px;
          line-height: 32px;
          text-align: center;
        }

        &.rs-pagination-btn-active {
          a {
            background: #dae3ed;
            font-weight: 600;
            text-align: center;
          }
        }

        .rs-icon-page-previous:before,
        .rs-icon-page-top:before,
        .rs-icon-page-next:before,
        .rs-icon-page-end:before {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
        }

        .rs-icon-page-top:before {
          background: url('../../../icons/pagination/btn-pagination-first.svg') no-repeat center;
        }

        .rs-icon-page-previous:before {
          background: url('../../../icons/pagination/btn-pagination-prv.svg') no-repeat center;
        }

        .rs-icon-page-next:before {
          background: url('../../../icons/pagination/btn-pagination-next.svg') no-repeat center;
        }

        .rs-icon-page-end:before {
          background: url('../../../icons/pagination/btn-pagination-last.svg') no-repeat center;
        }
      }
    }
  }

  .requestInfo {
    flex: 1 1 auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #6b7682;
    text-align: right;
    margin-right: 18px;

    img {
      margin-right: 5px;
      margin-top: -2px;
      height: 20px;
    }
  }

  #registButton {
    float: right;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: url('../../../icons/common/ic_plus.png') no-repeat center #FE7A7A;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }

  .btn:disabled,
  .btn:disabled:hover,
  .rs-btn:disabled,
  .rs-btn:disabled:hover,
  .rs-btn-default.rs-btn-disabled,
  .rs-btn-default.rs-btn-disabled:hover {
    opacity: 0.4;
    cursor: not-allowed;
  }
}