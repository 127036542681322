.rs-picker-daterange-menu {
  z-index: 9999;
  margin-top: 4px;
  .rs-calendar {
    width: 308px;
    height: 335px;
  }
  .rs-picker-daterange-calendar-group {
    height: 335px;
    min-width: 620px;
  }
  .rs-calendar-table-cell-content {
    font-family: 'Spoqa Han Sans';
    font-weight: 400;
    font-size: 13px;
    color: #2c3238;
    width: 28px;
    height: 28px;
  }
  .rs-calendar-table-cell {
    width: 40px;
    height: 40px;
    padding: 4px 8px;
  }
  .rs-calendar-table-header-row {
    .rs-calendar-table-cell-content {
      span {
        font-size: 13px;
        color: #8996a3;
      }
    }
  }
  .rs-calendar-table-cell-content:hover {
    background: none;
  }
  .rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content {
      border-color: #e0205c;
      background-color: #e0205c;
      border-radius: 100%;
      color: #fff;
    }
  }
  .rs-calendar-table-cell-un-same-month {
    span {
      color: #bec9d4;
    }
  }
  .rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-content {
      border-color: #56606b;
      background: #56606b;
      color: #fff;
      border-radius: 100%;
    }
  }
  .rs-picker-toolbar-ranges {
    margin-left: 0;
  }
  .rs-picker-toolbar-right-btn-ok {
    background: none;
    color: #e0205c;
    span {
      font-size: 12px;
    }
  }
  .rs-picker-toolbar-right-btn-ok:hover {
    background-color: none;
    span {
      color: #e0205c;
    }
  }
  .rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
    background: none;
  }
  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
    background: none;
  }
  .rs-calendar-table-cell-selected::before,
  .rs-calendar-table-cell-in-range::before {
    height: 30px;
    background-color: #f5f8fa;
  }
  .rs-calendar-header-title {
    position: absolute;
    left: 20px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    letter-spacing: -0.2px;
  }
  .rs-calendar-header-backward {
    position: absolute;
    right: 40px;
  }
  .rs-picker-daterange-header {
    display: none;
  }
  .rs-calendar-view {
    padding-top: 14px;
  }
  .rs-calendar {
    padding-top: 18px;
  }
  .rs-calendar-table-cell-selected:before,
  .rs-calendar-table-cell-in-range:before {
    height: 34px;
    top: 3px;
    left: 3px;
    margin: 0;
  }
}

.rs-pick-menu {
  .rs-calendar {
    .rs-calendar-table-header-row {
      .rs-calendar-table-cell-content {
        span {
          font-size: 13px;
        }
      }
    }
  }
}

.rs-picker-toolbar {
  .rs-picker-toolbar-option {
    margin-right: 6px;
    font-size: 12px;
    color: #56606b;
    padding: 2px 8px;
    background-color: #e9eff5;
    border-radius: 11px;
    span {
      font-size: 12px;
    }
  }
  .rs-picker-toolbar-option:not(:last-child)::before {
    width: 0;
  }
}
