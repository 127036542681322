.enter-search-bar {
  #CommonEnterSearchBarValueClearBtn {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 10px;
    height: 18px;
    padding: 0;
    font-size: 15px;
    background: transparent;
    color: #a6a6a6;
    text-indent: -99999px;
    z-index: 9;
    &:before,
    &:after {
      position: absolute;
      top: 2px;
      left: 5px;
      content: '';
      display: block;
      width: 1px;
      height: 12px;
      background: #a6a6a6;
      transform: rotate(47deg);
    }
    &:after {
      transform: rotate(-47deg);
    }
  }

  .rs-form-control-wrapper > .rs-input {
    height: 36px;
    width: auto;
    min-width: 200px;
    font-size: 12px;
    color: #56606b;
    border-color: #a5b3c2;
    border-radius: 3px;
    background: url('../../icons/common/search_gray.svg') no-repeat 10px 50% #fff;
    background-size: auto 14px;
    padding: 6px 32px 6px 30px;
    &::placeholder {
      color: #bec9d4;
      font-size: 12px;
    }
    &.on {
      background: url('../../icons/common/search_blue.svg') no-repeat 10px 50% #f0fdff;
      background-size: auto 14px;
      border: 1px solid #26b9d1;
      color: #26b9d1;
    }
  }
}
