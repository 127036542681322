#BoardRegistForm,
#BoardEditForm {
  padding-bottom: 50px;
  padding-top: 36px;
  width: 900px;
  min-width: 900px;
  padding-left: 14px;
  padding-right: 14px;
  margin: auto;

  .title {
    margin-bottom: 20px;
  }

  #title {
    height: 34px;
    width: 207px;
    color: #2C3238;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 34px;
    margin-right: 3px;
  }

  .subTitle {
    height: 34px;
    width: 207px;
    color: #2C3238;
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 0.18px;
    line-height: 34px;
    margin-right: 3px;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .form-row {
    margin-left: 0;
    margin-right: 0;

    >.row {
      padding-left: 0;
      padding-right: 0;
    }

  }

  .col {
    padding-left: 0;
    padding-right: 0;
  }

  .inputTitle {
    height: 38px;
    width: 660px;
    color: #2C3238;
    font-size: 14px;
    letter-spacing: 0.11px;
    line-height: 20px;
    margin-right: 17px;
    border: 1px solid #BEC9D4;
  }

  .inputTitle::placeholder {
    color: #bec9d4 !important;
  }

  .inputTitle:focus {
    box-shadow: none;
  }

  .category-row {
    width: 100%;
    margin-bottom: 30px;
  }

  .categorySelect {
    width: 200px;
    height: 38px;

    .category {
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      border: 1px solid #BEC9D4;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-width: 0;
      top: -7px;
    }
  }

  .btnArea {
    margin-top: 20px;
    text-align: right;

    .btn {
      margin-top: 2px;
      margin-right: 6px;
      box-sizing: border-box;
      height: 36px;
      width: 66px !important;
      border: 1px solid #bec9d4;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: none;

      p {
        height: 17px;
        width: 100%;
        color: #56606b;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 15px;
      }
    }

    #registBtn {
      background-color: #FE7A7A;

      p {
        color: #ffffff;
      }
    }
  }
}