// Override default variables before the import

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$primary: #a50034 !default;
$secondary: #439099 !default;
$dark: #333333 !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
