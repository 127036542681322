.rs-picker-menu {
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.17), 0 4px 5px 0 rgba(0, 0, 0, 0.11);
  margin-top: 4px;
  &.rs-picker-check-menu {
    &.menuOption {
      z-index: 99;
      min-width: 200px !important;
      .rs-check-item {
        .rs-checkbox-checker {
          min-height: 30px;
          label {
            font-size: 12px;
            font-weight: 400;
            color: #2c3238;
            padding: 5px 12px 5px 34px;
            .common-picker-item {
              font-size: 12px;
              margin-bottom: 0;
            }
          }
          .rs-checkbox-wrapper {
            width: 14px;
            height: 14px;
            top: 8px;
            .rs-checkbox-inner {
              &:before {
                width: 14px;
                height: 14px;
                border-radius: 1px;
                border: 1px solid #a5b3c2;
              }
            }
            &:before {
              width: 14px;
              height: 14px;
            }
            &:after {
              left: 0;
              top: 0;
              bottom: initial;
              right: initial;
            }
          }
        }
        &.rs-checkbox-checked {
          .rs-checkbox-wrapper {
            [type='checkbox']:focus ~ .rs-checkbox-inner::before {
              box-shadow: none;
            }

            .rs-checkbox-inner {
              &:before {
                background: #26b9d1;
                border-color: #26b9d1;
              }
              &:after {
                margin-top: 1px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .picker-footer {
        border-top: 1px solid #dae3ed;
        button {
          font-size: 12px;
          color: #26b9d1;
          width: 100%;
          padding: 10px 0;
          background: none;
        }
      }
    }
  }
}
