#MemberRegistrationForm {
  width: 100%;
  .form-row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-label {
    color: #424952;
    font-size: 14px;
    line-height: 20px;
  }
  .form-group {
    width: 100%;
  }

  input {
    border: 1px solid #bec9d4;
    border-radius: 6px;
    background-color: #ffffff;
    background-size: 0;
    outline: none;
    box-shadow: none;
    padding: 0;
    height: 60px;
    font-size: 13px;
    padding: 10px 20px;
    &:focus {
      border: 1px solid;
    }
    &#password {
      margin-bottom: 10px;
    }
  }
  .top-empty-space {
    margin-top: 20px;
  }
  .submit-button {
    height: 49px;
    width: 430px;
  }
  .phone-input-div {
    width: 430px;
  }
  .col-md-8 {
    padding: 0px;
  }
  .no-label-form-control {
    margin-top: -7px;
  }
  .component-display-none {
    display: none;
  }
  .comonent-display {
    display: inline;
  }
  .input-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .checked-area {
    float: right;
    margin-top: -40px;
    margin-right: 20px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
  }
  .certificationReRequest {
    cursor: pointer;
    height: 32px;
    opacity: 0.5;
    color: black;
    font-size: 16px;
    line-height: 32px;
    text-decoration: underline;
  }

  #registrationButton {
    width: 100%;
    height: 60px;
    background-color: #26b9d1;
    color: #ffffff;
    font-size: 15px;
    border: none;
    &:disabled {
      background-color: #f5f8fa;
      color: #A5B3C2;
      font-weight: 600;
      border: 2px solid #bec9d4;
    }
  }
}
