#BoardDetailContainer {
  width: 100%;
  margin-top: 36px;
  padding: 30px 50px 30px 40px;

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .btnArea{
    text-align: right;
    float: right !important;
    .btn{
      margin-top: 10px;
      margin-right: 6px;
      box-sizing: border-box;
      height: 36px;
      width: 66px !important;
      border: 1px solid #bec9d4;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: none;
      padding-left: 0;
      padding-top: 0;
      padding-right: 0;
      p{
        height: 17px;
        width: 100%;
        color: #56606b;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 15px;
      }
    }

    #listBtn
    #deleteBtn {
      border-color: #BEC9D4;
      border-style: solid;
      p {
        color: #ffffff;
      }
    }

    #editBtn {
      background-color: #424952;
      border-color: #424952;
      p {
        color: #ffffff;
      }
    }
  }
}