#BoardListContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 30px 50px 30px 40px;
  & > .row {
    flex: 1 1 auto;
    margin: 0;
    & > .col {
      padding: 0;
    }
  }
  .search {
    flex: 0 0 auto;
    margin-bottom: 20px;
  }
}