#loginSection {
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 100%;
  .container {
    height: 100%;
    max-width: none;
    & > .row {
      display: block;
      height: 100%;
      overflow: hidden;
    }
  }
  .logo {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.2px;
    img {
      width: 106px;
      height: auto;
    }
  }
  .LoginSection-title {
    margin-bottom: 40px;
  }
  .loginTitle {
    margin-top: 40px;
    h2 {
      font-size: 40px;
      margin: 0;
      line-height: 52px;
      color: #000;
      font-weight: 400;
      letter-spacing: -0.47px;
      strong {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
  .LoginSection-form {
    position: relative;
    width: 100%;
    > .row {
      margin-left: 0;
      margin-right: 0;
    }
    .Loginform-component {
      width: 100%;
      min-height: 400px;
      padding-top: 38px;
    }
    .LoginButton-component {
      margin-bottom: 53px;
    }
  }

  .loginContainer-col {
    max-width: 620px;
    margin: 0 auto;
    padding: 80px 110px 55px 106px;
  }
}
