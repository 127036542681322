#navBar {
  width: 100%;
  height: 100%;

  .navbar {
    a {
      padding-left: 16px;
      padding-right: 16px;

      #logo {
        width: 50px;
      }
    }

    .trans-lang-button {
      width: 70px;
      height: 24px;
      font-size: 11px;
      margin-left: 5px;
      color: #ffffff;
      border: solid 1px #56606B;
      background: #56606B;
      border-radius: 20px;
      padding: 3px 12px 3px 12px;
      text-align: center;
      outline: none !important;
      box-shadow: none !important;
    }

    .logout-button {
      position: absolute;
      top: 15px;
      left: calc(98% - 80px);
      width: 70px;
      height: 24px;
      font-size: 11px;
      color: #ffffff;
      border: solid 1px #56606B;
      background: #56606B;
      border-radius: 20px;
      padding: 3px 12px 3px 12px;
      text-align: center;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}