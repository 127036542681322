#LoginFooter {
  margin: 0px;
  padding-top: 16px;
  width: 100%;
  border-top: solid 1px #dae3ed;

  .signUp {
    font-size: 13px;
    float: left;
    a {
      color: #56606b;
      margin-left: 10px;
    }
  }
  button {
    background-color: #f5f8fa;
    border: none;
    cursor: pointer;
    font-size: 13px;
    color: #56606b;
    text-decoration: underline;
    font-weight: 500;
  }
  .changePassword {
    float: right;
  }
}