#BoardDetailInfo {

  #category {
    width: 61px;
    height: 20px;
    background-color: #26B9D1;
    color: #FFFFFF;
    display: flex;
    margin-bottom: 7px;
    span {
      margin: 0 auto;
      font-size: 12px;
    }
  }

  #title {
    height: 34px;
    color: #2C3238;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 34px;
    margin-bottom: 25px;
  }

  .info {
    margin-right: 20px;
    color: #56606B;
    font-size: 12px;
  }

  #contents {
    white-space: pre;
    size: 13px;
    color: #2C3238;
    line-height: 25px;
  }

  .btnArea{
    text-align: right;
    float: right !important;
    .btn{
      margin-top: 10px;
      margin-right: 6px;
      box-sizing: border-box;
      height: 36px;
      width: 66px !important;
      border: 1px solid #bec9d4;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: none;
      padding-left: 0;
      padding-top: 0;
      padding-right: 0;
      p{
        height: 17px;
        width: 100%;
        color: #56606b;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 15px;
      }
    }

    #listBtn
    #deleteBtn {
      border-color: #BEC9D4;
      border-style: solid;
      p {
        color: #ffffff;
      }
    }

    #editBtn {
      background-color: #424952;
      border-color: #424952;
      p {
        color: #ffffff;
      }
    }
  }
}