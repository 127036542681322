#loginForm {
  width: 100%;
  margin-bottom: 36px;

  .row>* {
    padding: 0;
  }

  .form-row {
    margin: 0;

    &+.form-row {
      margin-top: 12px;

      &.check-row {
        margin-top: 16px;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 0;
  }

  .form-control {
    border: 1px solid #bec9d4;
    border-radius: 6px;
    color: #000000;
    background-color: #ffffff;
    background-size: 0;
    outline: none;
    box-shadow: none;
    padding: 0;
    height: 60px;
    font-size: 13px;
    padding: 10px 20px;

    &:focus {
      border: 1px solid;
    }
  }

  .formSubmitButton {
    width: 100%;
    height: 60px;
    background-color: #26b9d1;
    color: #ffffff;
    font-size: 15px;
    border: none;

    &:disabled {
      background-color: #f5f8fa;
      color: #A5B3C2;
      font-weight: 600;
      border: 2px solid #bec9d4;
    }

    &:enabled &:hover {
      background-color: #0590a6
    }
  }

  .feedback {
    color: #F94B50;
    font-size: 12px;
    margin-top: 10px;
  }

  .formSaveIdCheck {
    .form-check-input {
      width: 40px;
      height: 19px;
      opacity: 0;
      cursor: pointer;

      &:checked~.form-check-label:before {
        border-color: #FE7A7A;
        background-color: #FE7A7A;
      }

      &:checked~label::after {
        content: 'Γ';
        background-image: none;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: #fff;
        transform: rotate(-135deg);
      }
    }

    .form-check-label {
      margin-left: 5px;
      vertical-align: middle;
      float: left;
      cursor: pointer;
      font-size: 12px;
      color: #2c3238;

      &:before {
        position: absolute;
        // top: calc(50% - 9px);
        left: 3%;
        display: block;
        pointer-events: none;
        content: '';
        border: 1px solid #bec9d4;
        background-color: #ffffff;
        width: 16px;
        height: 16px;
        box-shadow: none !important;
      }

      &:after {
        content: 'Γ';
        position: absolute;
        // top: -2px;
        left: 17px;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: #fff;
        transform: rotate(-135deg);
      }
    }
  }
}