#TermsAcception {
  width: 100%;

  form {
    padding: 0px;
    width: 100%;

    .custom-control-input:checked ~ .custom-control-label::before {
      color: white;
      border-color: #FE7A7A !important;
      background-color: #FE7A7A;
      padding: 0px;
    }
    .custom-control-input:active ~ .custom-control-label::before {
      color: white;
      border-color: #FE7A7A !important;
      background-color: #FE7A7A;
      padding: 0px;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(#FE7A7A, 0.25);
    }
    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #FE7A7A !important;
    }

    .custom-control-input:valid ~ .custom-control-label::before {
      border-color: silver;
    }

    .custom-control-input:valid ~ .custom-control-label {
      color: black;
    }

    label {
      font-size: 18px;
    }

    .term-desc {
      height: 19px;
      color: black;
      font-size: 16px;
      line-height: 19px;
      opacity: 0.6;
    }

    .left-empty-space {
      padding-left: 25px;
    }

    .bold {
      font-weight: bold;
    }

    .row {
      margin-left: 0px;
      margin-right: 0px;

      margin: 20px 0px;
    }

    .top-empty-space {
      margin-top: 0px;
    }

    .bottom-empty-space {
      margin-bottom: 0px;
    }

    .col-sm-1 {
      padding: 0px;
    }

    .col-sm-11 {
      padding: 0px;
    }

    .term-detail-icon {
      cursor: pointer;
      font-size: 25.33px;
      color: #979797;
    }

    .seperation-line {
      background-color: #979797;
      margin-top: 24px;
      height: 1px;
      margin-bottom: 24px;
    }

    .agree-button {
      width: 100%;
      height: 60px;
      background-color: #26b9d1;
      color: #ffffff;
      font-size: 15px;
      border: none;
      &:disabled {
        background-color: #f5f8fa;
        color: #A5B3C2;
        font-weight: 600;
        border: 2px solid #bec9d4;
      }
    }
  }
}