#BoardListSearchSection {
  width: 100%;

  &>.row {
    margin: 0;

    &>.col {
      width: auto;
      flex: 0 0 auto;
      padding: 0;
      margin-right: 8px;

      &.mLauto {
        margin-right: 0;
      }
    }
  }

  .rs-picker-toggle-wrapper {
    .rs-picker-toggle {
      height: 36px;
      padding: 5px 12px;
      background: #ffffff;
      border: 1px solid #bec9d4;
      box-sizing: border-box;
      border-radius: 3px;

      .rs-picker-toggle-value {
        line-height: 24px;

        .label {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 11px;
          letter-spacing: 0.2px;
          color: #56606b;
        }
      }

      .rs-picker-toggle-placeholder {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #56606b;
      }

      .rs-picker-toggle-caret {
        display: none;

        &:before {
          display: none;
        }
      }
    }

    &.rs-picker-has-value {
      .rs-picker-toggle {
        background: #f0fdff !important;
        border: 1px solid #26b9d1;
      }

      .rs-picker-toggle-value {
        div {
          display: flex;
          flex-flow: row;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.1px;
          color: #26b9d1;

          .label {
            flex: 0 0 auto;
            margin-right: 6px;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 10px;
            letter-spacing: 0.2px;
            color: #56606b;
          }

          .rs-picker-value-list {
            flex: 1 1 auto;
            line-height: 23px;

            .rs-picker-value-item,
            .rs-picker-value-separator {
              font-weight: 400;
              font-size: 12px;
              letter-spacing: 0.1px;
              color: #26b9d1;
            }
          }

          .rs-picker-value-count {
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding: 0;
            margin: 0 0 0 7px;
            text-align: center;
            font-size: 10px;
            background: #26b9d1;
          }
        }
      }

      .rs-picker-toggle-clean {
        top: 10px;
        right: 12px;
        font-size: 11px;
      }
    }

    &.rs-picker-cleanable {
      .rs-picker-toggle {
        padding-right: 32px;
      }
    }
  }

  .btn-refresh {
    height: 36px;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #8996a3;

    &:hover {
      color: #56606b;
    }
  }

  #title {
    margin-right: 16px;
    white-space: nowrap;
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    letter-spacing: -0.4px;
    color: #2C3238;
  }

  #search-totalCount {
    font-family: 'Spoqa Han Sans';
    font-weight: 700;
    width: auto;
    height: auto;
    padding: 2px 7px;
    border-radius: 10px;
    color: #424952;
    background-color: #dae3ed;
    font-size: 10px;
    margin-top: 9px;
  }

  .search.title {
    height: 36px;
    background: url('../../../icons/common/search_gray.svg') no-repeat 10px 50% #fff;
    background-size: auto 14px;
    padding: 6px 10px 6px 30px;
    border-color: #a5b3c2;
    border-radius: 3px;

    input.rs-input {
      width: 140px;
      vertical-align: middle;
    }

    &.rs-input-group-focus {
      border-color: #26b9d1;
    }

    &:hover {
      border-color: #a5b3c2;
    }

    .rs-input::placeholder {
      display: inline-block;
      color: #bec9d4;
      font-size: 12px;
      vertical-align: top;
    }

    &.on {
      padding: 9px 10px 5px 30px;
      background: url('../../../icons/common/search_blue.svg') no-repeat 10px 50% #f0fdff;
      background-size: auto 14px;
      border-color: #26b9d1;

      input.rs-input {
        font-size: 12px;
        background: #f0fdff;
        color: #26b9d1;
      }
    }
  }

  .s-title span {
    margin-right: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #000;

    &.incoming {
      color: #005f6e;
    }
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px #f0fdff inset;
    -webkit-text-fill-color: #26b9d1 !important;
  }

  .drop-menu {
    .rs-btn {
      .rs-picker-toggle-caret {
        display: none;
      }
    }
  }
}