#WorkSideNavSection {
  margin-top: 20px;
  font-size: 1.8em;
  .accordion{
    margin-top: 25px;
  }

  .toggleMenu {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #2C3238;
    background: #FFFFFF;
    border: none;
    padding-left: 1.5rem;
  }

  .nav-link {
    position: relative;
    height: 34px;
    line-height: 34px;
    padding: 0 0 0 42px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #2c3238;
    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 8px);
      left: 20px;
      display: inline-block;
      width: 16px;
      height: 16px;
    }
    &.active {
      background-color: #f0fdff;
      border-left: 4px solid #42c9de;
      padding-left: 38px;
      font-weight: 600;
      &:before {
        left: 16px;
      }
    }
  }

  .accordion {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 20px;
      display: inline-block;
      width: 16px;
      height: 16px;
    }
    .btn-link {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0 0 0 42px;
      border: none;
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.1px;
      color: #2c3238;
      text-decoration: none;
    }
    .nav-link {
      position: relative;
      padding-left: 54px;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        left: 42px;
        display: inline-block;
        width: 2px;
        height: 2px;
        background-color: #2c3238;
      }
      &.active {
        padding-left: 50px;
        &:before {
          left: 38px;
        }
      }
    }
  }

  .btn-primary:focus {
    box-shadow: none;
  }

  .card-box {
    border-radius: 0.8rem;
    margin-left: 3%;
    width: 94%;
    height: fit-content;

  }
  .rs-dropdown-menu {
    position: absolute;
    min-width: 120px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.17), 0 4px 5px 0 rgba(0, 0, 0, 0.11);
    padding: 5px 0;
    z-index: 11;
    .rs-dropdown-item {
      .rs-dropdown-item-content {
        font-size: 12px;
        padding: 6px 12px;
        img {
          display: inline-block;
          vertical-align: middle;
          margin-top: -3px;
          margin-right: 8px;
        }
      }
    }
  }
  .rs-panel{
    overflow: visible !important;
  }
}