#MemberRegistrationComplete {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    .memberInfo {
      height: 95px;
      background-color: #ffffff;
      border: 1px solid #E9EFF5;
      padding: 18px 10px 10px 30px;
  
      .info-line {
        font-size: 14px;
        display: -webkit-box;
        margin-bottom: 15px;
        .dot {
          position: relative;
          top: -5px;
          left: 4px;
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: #000000;
        }
        .infoTitle {
          margin-left: 15px;
          width: 30%;
        }
        .info {
          margin-left: 15px;
        }
      }
    }
    .guideMessage {
      margin-top: 20px;
      font-size: 13px;
      color: #8996A3;
    }
    .toLogin {
      margin-top: 50px;
      background-color: #f5f8fa;
      border: none;
      cursor: pointer;
      font-size: 15px;
      color: #26B9D1;
      text-decoration: underline;
      font-weight: 700;
    }
  }