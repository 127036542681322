#WorkPage {
  height: 100%;
  overflow: auto;
  .rs-loader-wrapper {
    max-width: 100%;
    height: 100%;
    z-index: 99999;
  }
  .rs-loader-backdrop {
    background: #333;
    opacity: 0.5;
  }
  .rs-loader-spin {
    display: none;
  }

  .navbar {
    color: white;
  }
  .container {
    margin-top: 30px;
  }
}