#loginContainer {
  height: 100% !important;
  width: 100%;
  background-color: #f5f8fa;
  .container-fluid {
    height: 100% !important;
    & > .row {
      height: 100%;
      align-items: center;
    }
  }
  .row-footer {
    width: 100%;
  }
  #CommonToast {
    width: 100%;
  }
}
