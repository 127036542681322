#MainSection {
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 100%;

  .container {
    max-width: 620px;
    margin: 0 auto;
    padding: 80px 110px 55px 106px;
  }

  .mainSectionTitle {
    width: 100%;
    margin-bottom: 38px;
    margin-top: 40px;
    .logo {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 14px;
      img {
        width: 106px;
        height: auto;
      }
      .stepStatus {
        float: right;
      }
    }
    h2 {
      font-size: 32px;
      margin: 0;
      line-height: 52px;
      color: #000;
      font-weight: 400;
      letter-spacing: -0.47px;
      strong {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}
